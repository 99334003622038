<script setup>
// import AppAuth from "@/components/AppAuth.vue";
</script>
<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid px-0">
      <router-link to="/" class="navbar-brand py-0 me-0">
        <img src="/img/logo.svg" alt="CHICKO" width="207" height="100" />
      </router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="offcanvas offcanvas-end bg-success text-white"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header p-2 pe-4">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            <img src="/img/logo.svg" alt="CHICKO" width="207" height="100" />
          </h5>
          <button
            type="button"
            class="btn-close bg-white"
            data-bs-dismiss="offcanvas"
            aria-label="Закрыть"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <!--                        <li class="nav-item">-->
            <!--                            <a class="nav-link active" aria-current="page" href="#">Home</a>-->
            <!--                        </li>-->
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'check-hall' }"
                >Инструкция проверки зала</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'rules' }"
                >Правила тайного гостя</router-link
              >
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'faq' }">Вопрос ответ</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  data() {
    return {
      ref: null,
    };
  },
  methods: {
    //
  },
  mounted() {
    this.$router.isReady(() => {
      document.querySelectorAll('a[href^="#"]').forEach(function (anchor) {
        anchor.addEventListener("click", function (e) {
          e.preventDefault();

          document.querySelector(this.getAttribute("href")).scrollIntoView({
            behavior: "smooth",
          });
        });
      });
    });
  },
};
</script>
