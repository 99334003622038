<script setup>
import HeaderProfile from '../components/AppHeaderProfile.vue'
</script>
<template>
  <HeaderProfile/>
  <div class="row text-uppercase">
      <div class="col-lg-5 fs-4 fw-bold m-0">
        {{check.data_check}}, {{check.type}}
      </div>
    <div class="col-lg-2">
      <div class="py-1 bg-blue rounded-pill text-center">
        {{check.status}}
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-lg-2">
      <div class="fw-lighter mb-2">
        Дата проверки
      </div>
      <div class="py-1 bg-blue rounded-pill text-center">
        {{check.data_check}}
      </div>
    </div>
    <div class="col-lg-4">
      <div class="fw-lighter mb-2">
        Адресс проверки
      </div>
      <div class="py-1 bg-blue rounded-pill text-center">
        {{check.address}}
      </div>
    </div>
    <div class="col-lg-2">
      <div class="fw-lighter mb-2">
        Тип проверки
      </div>
      <div class="py-1 bg-blue rounded-pill text-center">
        {{check.type}}
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="fs-6 text-white text-start fw-lighter">Что нужно заказать</div>
  </div>
  <div class="row mt-2">
    <div class="fs-5 text-white text-start fw-normal">
      {{check.listBy.join(', ')}}
    </div>
  </div>
  <form @submit="submitForm" ref="Form">
  <div v-for="form in forms" :key="form">
    <div class="mt-4" v-html="form"></div>
  </div>
    <button type="submit" class="mt-4 btn btn-danger b-0 rounded-pill py-1 px-3 fw-normal fc-white bg-red">Отправить</button>
  </form>

</template>

<script>

export default {
  name: 'CheckRequestPage',
  props: ['propValue'],
  data() {
    return {
      check: JSON.parse(this.propValue),
      forms: {
        form1:'<div class="col-lg-4"><textarea class="form-control" name="exampleFormControlTextarea1"   rows="3"></textarea></div>',
        form2:'<div class="col-lg-4"><textarea class="form-control" name="exampleFormControlTextarea2"   rows="3"></textarea></div>',
        form3:'<div class="col-lg-4"><textarea class="form-control" name="exampleFormControlTextarea3"   rows="3"></textarea></div>',
        form4:'<div class="col-lg-4"><textarea class="form-control" name="exampleFormControlTextarea4"   rows="3"></textarea></div>',
        form5:'<div class="col-lg-4"><textarea class="form-control" name="exampleFormControlTextarea5"   rows="3"></textarea></div>',
      }
    };
  },
  methods:{
    submitForm(event) {
      event.preventDefault();
      const form = this.$refs.Form;
      for (let i = 0; i < form.elements.length-1; i++) {
        const field = form.elements[i];
        console.log(`Поле ${field.name}: ${field.value}`);
      }
    },
  }
}
</script>

<style scoped>

</style>