<script setup>
import BonusPoints from "@/components/BonusPoints.vue";
import HeaderProfile from "../components/AppHeaderProfile.vue";
</script>

<template>
  <HeaderProfile />
  <section class="my-data fw-light pb-3">
    <div class="d-flex align-items-center row">
      <div class="col-lg-3">
        <h3 class="fw-bold">Твои данные</h3>
      </div>
      <div class="col-lg-3 px-0">
        <BonusPoints />
      </div>
    </div>
    <form
      class="mt-3 mt-lg-5 needs-validation"
      method="post"
      novalidate
      @submit="onSubmit"
    >
      <div class="col-lg-4">
        <!-- Name -->
        <div class="mb-3">
          <label for="inputName" class="form-label">Имя</label>
          <input
            type="text"
            v-model="currentCustomer.name"
            name="name"
            class="form-control rounded-pill"
            id="inputName"
            placeholder="Введите имя"
            required
          />
        </div>
        <!-- Phone -->
        <div class="mb-3">
          <label for="inputPhone" class="form-label">Телефон</label>
          <input
            type="tel"
            v-model="currentCustomer.phone"
            name="phone"
            class="form-control rounded-pill mobile-phone"
            id="inputPhone"
            placeholder="+7(999)999-9999"
            required
          />
        </div>

        <!-- Email -->
        <!-- <div class="mb-3">
                    <label for="inputEmail" class="form-label">Email</label>
                    <input type="email" v-model="currentCustomer.email" name="email" class="form-control rounded-pill"
                           id="inputEmail" placeholder="Введите email" required>
                </div> -->
        <!-- City -->
        <div class="mb-3">
          <label for="inputCity" class="form-label">Город</label>
          <input
            type="text"
            v-model="currentCustomer.city.name"
            name="city"
            class="form-control rounded-pill"
            id="inputCity"
            placeholder="Укажите город"
            required
            readonly
          />
        </div>
        <button
          type="submit"
          class="border-0 mt-4 bg-danger px-5 btn btn-primary"
          :class="{ disabled: loading }"
        >
          <span
            class="spinner-grow spinner-grow-sm"
            aria-hidden="true"
            v-if="!loading"
          ></span>
          Сохранить
          <span
            class="spinner-grow spinner-grow-sm"
            aria-hidden="true"
            v-if="!loading"
          ></span>
        </button>
      </div>
    </form>
  </section>
</template>

<script>
import Inputmask from "inputmask";
import CustomerService from "@/services/customer.service";

export default {
  name: "MyPage",
  data() {
    return {
      loading: true,
      resource: null,
      rest: null,
      aboba: "",
    };
  },
  methods: {
    setPhoneMask() {
      let fieldPhone = document.getElementsByClassName("mobile-phone");
      [].forEach.call(fieldPhone, (selector) => {
        Inputmask({
          mask: "+7(999)999-9999",
          clearMaskOnLostFocus: true,
        }).mask(selector);
      });
    },
    onSubmit(e) {
      e.preventDefault();
      const self = this;
      // self.loading = true;
      CustomerService.updatePersonalData(self.currentCustomer).then(
        (response) => {
          self.$swal({
            icon: "success",
            text: "Ваши персональные данные обновлены!",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          // self.loading = false;
          self.currentCustomer = response.data.data;
          let customerData = JSON.parse(localStorage.getItem("customer"));
          customerData.name = response.data.data.name;
          customerData.email = response.data.data.email;
          customerData.phone = response.data.data.phone;
          customerData.city = response.data.data.city_id;
          localStorage.setItem("customer", JSON.stringify(customerData));
        },
        (error) => {
          console.error(error);
          self.$swal({
            icon: "error",
            title: "Что-то пошло не так...",
            text: "Обновите страницу и попробуйте ещё раз.",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      );
    },
  },
  mounted() {
    this.setPhoneMask();
    console.log(this.rest);
    console.log(this.loading);
    if (!this.currentCustomer) {
      this.$router.push({ name: 'home' });
    }
    // const hash = window.location.hash.slice(1);
    // const params = new URLSearchParams(hash);
    // const initDataString = params.get("tgWebAppData");
    // const initData = new URLSearchParams(initDataString);
    // // const JSONdata = JSON.parse(initData);
    // if (initData === null) {
    //   alert("Вы не вошли");
    //   // throw new Error("Ooof! Something is wrong. Init data is missing");
    // } else {
    //   alert("Вы вошли");
    //   alert(initData);
      // let id = JSON.parse(decodeURIComponent(initData.get("user"))).id
      // this.$store.dispatch("auth/login", id).then(
      //   data => {
      //     alert(data);
      //   },
      //   (error) => {
      //     this.errorAlert(error.response.data.message);
      //   }
      // );
    // }
  },
  computed: {
    currentCustomer() {
      return this.$store.state.auth.customer;
    },
  },
};
</script>

<style scoped></style>
