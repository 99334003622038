<template>
    <div>
        
    </div>
</template>
<script>
export default {
    name: 'redirect-twa-checks',

    mounted() {
    const hash = window.location.hash.slice(1);
    const params = new URLSearchParams(hash);
    const initDataString = params.get("tgWebAppData");
    const initData = new URLSearchParams(initDataString);
    if (initData == null) {
      alert("Вы не вошли");
      this.$router.push({ name: 'home' });
    } else {
      let id = JSON.parse(decodeURIComponent(initData.get("user"))).id
      this.$store.dispatch("auth/login", id).then(
        () => {
          this.$router.push({ name: 'my-check' });
        },
        (error) => {
          this.errorAlert(error.response.data.message);
        }
      );
    }
  },
}
</script>
