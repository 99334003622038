import axios from 'axios'
import authHeader from './auth-header';
import store from '../store'

axios.defaults.withCredentials = false;

const customerData = store.state.auth.customer;

/**
 *
 */
class CustomerService {

  /**
   * Получить бонусные баллы.
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  getPoints () {
    return axios.get(`/customers/${customerData.id}/my-points`, { headers: authHeader() })
  }

  /**
   * Получить задания.
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  getTasks() { 
    return axios.get(`/customers/${JSON.parse(localStorage.getItem('customer')).id}/my-tasks`, { headers: authHeader() })
  }

  /**
   * Подтвердить задачу на проверку.
   *
   * @param task_id
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  confirmTask (task_id) {
    return axios.post(`/customers/${customerData.id}/confirm-task/${task_id}`, { headers: authHeader() })
  }

  /**
   * Подтверждение задания к началу выполнения.
   *
   * @param task_id
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  confirmCheck (task_id) {
    return axios.post(`/customers/${customerData.id}/confirm-check/${task_id}`, { headers: authHeader() })
  }

  /**
   * Получить все мои задания (История заданий)
   *
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  getChecks () {
    return axios.get(`/customers/${customerData.id}/my-checks`, { headers: authHeader() })
  }

  /**
   * Обновить персональные данные.
   *
   * @param data
   * @returns {Promise<axios.AxiosResponse<any>>}
   */
  updatePersonalData (data) {
    return axios.put(`/customers/${customerData.id}`, data, { headers: authHeader() })
  }

  saveReport(data) {
    return axios.post(`/reports`, data, { headers: authHeader() })
  }

  getReport(id) {
    console.log(this.customer)
    return axios.get(`/customers/report/${id}`, { headers: authHeader() })
  }
}

export default new CustomerService()