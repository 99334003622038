<template>
    <div class="wrapper">
        <div class="container mx-auto text-white">
            <router-view/>
        </div>
    </div>
</template>
<style>
@media (max-width: 767px) {
    .wrapper {
        min-width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}
</style>