<script setup>
import BonusPoints from "@/components/BonusPoints.vue";
import HeaderProfile from "../components/AppHeaderProfile.vue";
</script>

<template>
  <HeaderProfile />
  <section class="my-data fw-light">
    <div class="d-flex align-items-center row">
      <div class="col-lg-3">
        <h3 class="fw-bold">Твои награды</h3>
      </div>
      <div class="col-lg-3 px-0">
        <BonusPoints />
      </div>
    </div>
    <div v-if="rewards.length == 0" class="row d-flex align-content-center">
      <div class="col-12 min-vh-100">
        <h4 class="text-center mt-5">
          Пока ничего нет. Чтобы заработать награды,
          <router-link :to="{ name: 'my-check' }" class="text-white">выполняйте задания</router-link>
          CHICKO
        </h4>
      </div>
    </div>
    <div v-else class="row d-flex align-content-center">
      <div v-for="(item, index) in rewards" :key="index">
        <p>
          Идентификатор награды - {{ item.id }}
        </p>
        <p>
          Идентификатор сертификата - {{ item.pivot.certificate_id }}
        </p>
        <p>
          Идентификатор кастомера - {{ item.pivot.customer_id }}
        </p>
        <a class="btn btn-success btn-lg " :href="item.file.path.replace(/^public/, 'https://admin.control.chicko.me/storage')" download="certificate">Скачать сертификат</a>
        <hr v-if="rewards.length != 1">
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import authHeader from "../services/auth-header";

export default {
  name: "MyAchievementPage",
  data() {
    return {
      rewards: []
    };
  },
  methods: {
    goToTasks() {
      this.$router.push("/my-check");
    },
  },
  mounted() {
    axios.get(`/${this.$store.state.auth.customer.id}/rewards`, { headers: authHeader() }).then((response) => {
      this.rewards = response.data;
      console.log(this.rewards)
      // this.rewards = [
      // JSON.parse({
      //     "id": 2,
      //     "name": "1000-1075000",
      //     "code": "1075000",
      //     "amount": 1000,
      //     "file_id": 2,
      //     "status": true,
      //     "issued": false,
      //     "created_at": "2023-09-05T15:48:35.000000Z",
      //     "updated_at": "2023-10-18T23:02:56.000000Z",
      //     "pivot": {
      //       "customer_id": 4,
      //       "certificate_id": 2
      //     },
      //     "file": {
      //       "id": 2,
      //       "name": "1075000",
      //       "path": "public/certificates/1000/1075000.pdf",
      //       "created_at": "2023-09-05T15:48:35.000000Z",
      //       "updated_at": "2023-09-05T15:48:35.000000Z"
      //     }
      //   }),
      //   JSON.parse({
      //     "id": 2,
      //     "name": "1000-1075000",
      //     "code": "1075000",
      //     "amount": 1000,
      //     "file_id": 2,
      //     "status": true,
      //     "issued": false,
      //     "created_at": "2023-09-05T15:48:35.000000Z",
      //     "updated_at": "2023-10-18T23:02:56.000000Z",
      //     "pivot": {
      //       "customer_id": 4,
      //       "certificate_id": 2
      //     },
      //     "file": {
      //       "id": 2,
      //       "name": "1075000",
      //       "path": "public/certificates/1000/1075000.pdf",
      //       "created_at": "2023-09-05T15:48:35.000000Z",
      //       "updated_at": "2023-09-05T15:48:35.000000Z"
      //     }
      //   }),
      //   JSON.parse({
      //     "id": 6,
      //     "name": "1000-1085000",
      //     "code": "1085000",
      //     "amount": 1000,
      //     "file_id": 15,
      //     "status": true,
      //     "issued": true,
      //     "created_at": "2023-09-05T15:53:41.000000Z",
      //     "updated_at": "2023-10-19T01:37:34.000000Z",
      //     "pivot": {
      //       "customer_id": 4,
      //       "certificate_id": 6
      //     },
      //     "file": {
      //       "id": 15,
      //       "name": "fgdfgdfgdf",
      //       "path": "public/AeozAKenttnmVHtrKJXi1vG83LMVUdzrhHzNXHFw.png",
      //       "created_at": "2023-10-18T13:43:05.000000Z",
      //       "updated_at": "2023-10-18T13:43:05.000000Z"
      //     }
      //   })
      // ]
      // console.log(this.rewards)
    })

    console.log(this.rewards)
  },
  // computed: {
  //     currentCustomer() {
  //         return this.$store.state.auth.customer;
  //     }
  // },
};
</script>

<style></style>
