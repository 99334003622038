<script setup>
import HeaderProfile from '../components/AppHeaderProfile.vue';
</script>

<template>
    <HeaderProfile/>
    form
    <div id="formio"></div>
</template>

<script>

export default {
    name: 'FormPage',
    data() {
        return {
            components: [
                {
                    "title": "Вход",
                    "collapsible": true,
                    "key": "group1",
                    "type": "panel",
                    "label": "Panel",
                    "collapsed": true,
                    "input": false,
                    "tableView": false,
                    "components": [
                        {
                            "label": "Вывеска чистая, исправна, включена (вечернее время)",
                            "optionsLabelPosition": "left",
                            "inline": true,
                            "tableView": false,
                            "values": [
                                {
                                    "label": "Да",
                                    "value": "1",
                                    "shortcut": "sd"
                                },
                                {
                                    "label": "Нет",
                                    "value": "0",
                                    "shortcut": ""
                                }
                            ],
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Необходимо выбрать ответ",
                            "key": "group1_radio1",
                            "type": "radio",
                            "input": true
                        },
                        {
                            "label": "Фото",
                            "tableView": false,
                            "storage": "base64",
                            "image": true,
                            "uploadOnly": true,
                            "webcam": true,
                            "capture": "environment",
                            "fileTypes": [
                                {
                                    "label": "",
                                    "value": ""
                                }
                            ],
                            "fileMaxSize": "10MB",
                            "multiple": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Обязательно сделайте фото",
                            "key": "group1_photo1",
                            "type": "file",
                            "input": true
                        },
                        {
                            "label": "Дверь чистая, без разводов",
                            "optionsLabelPosition": "left",
                            "inline": true,
                            "tableView": false,
                            "values": [
                                {
                                    "label": "Да",
                                    "value": "1",
                                    "shortcut": ""
                                },
                                {
                                    "label": "Нет",
                                    "value": "0",
                                    "shortcut": ""
                                }
                            ],
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Необходимо выбрать ответ",
                            "key": "group1_radio2",
                            "type": "radio",
                            "input": true
                        },
                        {
                            "label": "Фото",
                            "tableView": false,
                            "storage": "base64",
                            "image": true,
                            "uploadOnly": true,
                            "webcam": true,
                            "capture": "environment",
                            "fileTypes": [
                                {
                                    "label": "",
                                    "value": ""
                                }
                            ],
                            "fileMaxSize": "10MB",
                            "multiple": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Обязательно сделайте фото",
                            "key": "group1_photo2",
                            "type": "file",
                            "input": true
                        },
                        {
                            "label": "Мусорный бак и прилегающая территория чистые",
                            "optionsLabelPosition": "left",
                            "inline": true,
                            "tableView": false,
                            "values": [
                                {
                                    "label": "Да",
                                    "value": "1",
                                    "shortcut": ""
                                },
                                {
                                    "label": "Нет",
                                    "value": "0",
                                    "shortcut": ""
                                }
                            ],
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Необходимо выбрать ответ",
                            "key": "group1_radio3",
                            "type": "radio",
                            "input": true
                        },
                        {
                            "label": "Фото",
                            "tableView": false,
                            "storage": "base64",
                            "image": true,
                            "uploadOnly": true,
                            "webcam": true,
                            "capture": "environment",
                            "fileTypes": [
                                {
                                    "label": "",
                                    "value": ""
                                }
                            ],
                            "fileMaxSize": "10MB",
                            "multiple": true,
                            "validate": {
                                "required": true
                            },
                            "errorLabel": "Обязательно сделайте фото",
                            "key": "group1_photo3",
                            "type": "file",
                            "input": true
                        }
                    ]
                },
                // {
                //     "title": "Зал",
                //     "collapsible": true,
                //     "key": "group2",
                //     "type": "panel",
                //     "label": "Panel",
                //     "collapsed": true,
                //     "input": false,
                //     "tableView": false,
                //     "components": [
                //         {
                //             "label": "Стол и стул, на котором вы сидите чистые и не шатаются.",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "1",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group2_radio1",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group2_photo1",
                //             "type": "file",
                //             "input": true
                //         },
                //         {
                //             "label": "Неоны и все лампочки включены и исправны.",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "1",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group2_radio2",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group2_photo2",
                //             "type": "file",
                //             "input": true
                //         },
                //         {
                //             "label": "ТВ включены. По ТВ аниме или к-поп клипы.",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "2",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group2_radio3",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group2_photo3",
                //             "type": "file",
                //             "input": true
                //         },
                //         {
                //             "label": "Играет к-поп или тема из аниме.",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "2",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group2_radio4",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group2_photo4",
                //             "type": "file",
                //             "input": true
                //         }
                //     ]
                // },
                // {
                //     "title": "Гостевой туалет",
                //     "collapsible": true,
                //     "key": "group3",
                //     "type": "panel",
                //     "label": "Panel",
                //     "collapsed": true,
                //     "input": false,
                //     "tableView": false,
                //     "components": [
                //         {
                //             "label": "Унитаз и раковина чистые, в рабочем состоянии (унитаз смывает, в раковине есть вода).",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "2",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group3_radio1",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group3_photo1",
                //             "type": "file",
                //             "input": true
                //         },
                //         {
                //             "label": "Диспенсеры, сушилка для рук (при наличии) чистые, в рабочем состоянии.",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "2",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group3_radio2",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group3_photo2",
                //             "type": "file",
                //             "input": true
                //         },
                //         {
                //             "label": "В наличии: бумажные полотенца, туалетная бумага, мыло, крем для рук, освежитель для воздуха, ёршик (приложить фото толькл если чего-то нет).",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "2",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group3_radio3",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group3_photo3",
                //             "type": "file",
                //             "input": true
                //         },
                //         {
                //             "label": "Мусорное ведро чистое.",
                //             "optionsLabelPosition": "left",
                //             "inline": true,
                //             "tableView": false,
                //             "values": [
                //                 {
                //                     "label": "Да",
                //                     "value": "2",
                //                     "shortcut": ""
                //                 },
                //                 {
                //                     "label": "Нет",
                //                     "value": "0",
                //                     "shortcut": ""
                //                 }
                //             ],
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Необходимо выбрать ответ",
                //             "key": "group3_radio4",
                //             "type": "radio",
                //             "input": true
                //         },
                //         {
                //             "label": "Фото",
                //             "tableView": false,
                //             "storage": "base64",
                //             "image": true,
                //             "uploadOnly": true,
                //             "webcam": true,
                //             "capture": "environment",
                //             "fileTypes": [
                //                 {
                //                     "label": "",
                //                     "value": ""
                //                 }
                //             ],
                //             "fileMaxSize": "10MB",
                //             "multiple": true,
                //             "validate": {
                //                 "required": true
                //             },
                //             "errorLabel": "Обязательно сделайте фото",
                //             "key": "group3_photo4",
                //             "type": "file",
                //             "input": true
                //         }
                //     ]
                // },
                {
                    "type": "button",
                    "label": "Отправить",
                    "key": "submit",
                    "disableOnInvalid": false,
                    "input": true,
                    "tableView": false
                }
            ]
        }
    },
    methods: {
        saveForm(data) {
            console.log(data)
        }
    },
    mounted() {
        //
    },
    computed: {
    }
}
</script>