import { createApp } from 'vue'
import { createWebHistory } from 'vue-router'
import axios from 'axios'
import router from '../pages/routes.js'
import store from '../store'
import VueTheMask from 'vue-the-mask';
// import LoginForm from '@/components/AppLoginForm.vue'

// import * as Sentry from "@sentry/vue";

axios.defaults.baseURL = 'https://admin.control.chicko.me/api'
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

import App from './App.vue'
// Import our custom CSS
import '../scss/styles.scss'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

// Import only the Bootstrap components we need
import { Popover } from 'bootstrap'

// const router = createRouter(createWebHistory())

// router.beforeEach((to, from, next) => {
//   const loggedIn = LoginForm.computed.loggedIn;
//   const requareAuth = to.matched.some(my => my.meta.auth)

//   if (requareAuth && !loggedIn) {
//     next('/home')
//     console.log('Request false')
//   } else {
//     next()
//   }
// })

const app = createApp(App)

axios.get('/ping').then(() => {
  app.config.globalProperties.$online = true
}).catch(() => {
  app.config.globalProperties.$online = false
  const alertWrapper = document.createElement('div')
  alertWrapper.innerHTML = [
    `<div class="alert position-absolute z-3 alert-danger alert-dismissible" role="alert" style="top: 5px; left: 5px; right: 5px">`,
    `   <div>Упс... Что-то пошло не так. Мы уже знаем о проблеме. Исправляем.</div>`,
    '   <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Закрыть"></button>',
    '</div>',
  ].join('')

  const textWrapper = document.createElement('span')
  textWrapper.innerHTML = [
    `<small class="position-fixed" style="color:red;left: 5px; top: 5px; text-shadow: -1px 2px 2px #370000;">OFFLINE</small>`,
  ].join('')

  document.body.prepend(textWrapper)
  document.body.prepend(alertWrapper)
})

// Sentry.init({
//   app,
//   dsn: "https://dace07e2431a4f15f0dda81a6b8f2c84@o4505679593996288.ingest.sentry.io/4505683687833600",
//   integrations: [
//     new Sentry.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   // tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/admin.control.chicko\.me\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });
app.use(router).use(store).use(VueSweetalert2).use(VueTheMask).mount('#app')

// Create an example popover
document.querySelectorAll('[data-bs-toggle="popover"]').forEach(popover => {
  new Popover(popover)
})
