<script setup>
import Header from '../components/AppHeader.vue'
</script>
<template>
<Header/>
<div class="row">
    <div class="col-lg-12">
        <h1 class="text-center mb-3 mb-lg-5">Правила "Тайный гость" CHICKO</h1>
    </div>
    <div class="col-lg-12">
        <h2>Изучи основные правила:</h2>
        <ul>
            <li>никому не выдавай, что ты — Тайный покупатель CHICKO;</li>
            <li>внимательно изучи Бланк проверки Тайного покупателя (пришлём после изучения данных правил);</li>
            <li>проводи проверку только после того, как получил ссылку-приглашение (пришлём после одобрения анкеты);</li>
            <li>делай фотографии по Инструкции, высокого качества, четкие при хорошем освещении. Не используй вспышку и фильтры;</li>
            <li>встречу и принятие заказа на кассе записывай на диктофон;</li>
            <li>отправляй отчет точно в день проверки (ссылку на загрузку отчёта пришлём после проведения проверки);</li>
            <li>не удаляй фотографии и аудио-запись после публикации;</li>
            <li>в проекте может быть зарегистрирована только одна страница ТГ;</li>
            <li>сообщи нам (ссылка), если начал работать в CHICKO.</li>
        </ul>
    </div>
    <div class="col-lg-12">
        <h2>Мы будем вынуждены исключить из проекта и отменить проверку, если:</h2>
        <ul>
            <li>обманул нас;</li>
            <li>рассекретил себя;</li>
            <li>нецензурно выражался и вел себя грубо или агрессивно;</li>
            <li>регулярно занимал и не проводил проверки;</li>
            <li>являешься блогером и цель проверки – контент;</li>
            <li>являешься сотрудником CHICKO или у тебя есть знакомые, которые работают в компании.</li>
        </ul>
    </div>
    <div class="col-lg-12">
        <h2>Ознакомься с бонусами за проверки:</h2>
        <ul>
            <li>заказ, который ты делаешь во время проверки ресторана, фудкорта или доставки, нужно оплачивать реальными
                деньгами. За правильно составленный отчет мы начисляем бонусы;
            </li>
            <li>за проверку ресторана и доставки бонусы начисляются в среду, на следующей неделе после проверки до 18:00
                по московскому времени. Например, если проверка была в понедельник 1 марта, начисление в среду 10 марта;
            </li>
            <li>количество бонусов не зависит от суммы заказа:
                <ol>
                    <li>За проверку ресторана и фудкорта: 1000 бонусов (1 бонус=1 рубль).</li>
                </ol>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
export default {
  name: 'RulesPage',
}
</script>

<style scoped>

</style>