import axios from 'axios'

axios.defaults.withCredentials = false

class AuthService {
  // async login (customer) {
  //   const response = await axios.post('/customers/login', {
  //     email: customer.email,
  //     password: customer.password,
  //   })
  //   if (response.data.token) {
  //     localStorage.setItem('customer', JSON.stringify(response.data))
  //   }
  //   return response.data
  // }
    isAuthentificated() {
        return localStorage.getItem('token') !== null && localStorage.getItem('customer') !== null;
    }
  async login (telegram_id) {
    const response = await axios.post('/login', {
      telegram_id,
    }, {
      'Access-Control-Allow-Origin': '*'
    })
    if (response.data.token && response.data.customer) {
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('customer', JSON.stringify(response.data.customer));
    }
    return response.data
  }

  async telegramAuth (customer) {
    const response = await axios.post('/customers/telegram-auth', {telegram_data: customer})
    if (response.data.token) {
      localStorage.setItem('customer', JSON.stringify(response.data))
    }
    return response.data
  }

  logout () {
    localStorage.removeItem('customer')
  }

  register(customer) {
    return axios.post('/customers/register', {
      name: customer.name,
      email: customer.email,
      phone: customer.phone,
      city_id: customer.city,
      birthday: customer.birthday,
      telegram_data: customer.telegram_data,
      telegram_id: customer.telegram_id,
      source: customer.source
    })
  }
}

export default new AuthService()
