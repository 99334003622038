<script>
export default {
  props: {
    index: Number,
    question: String,
    answer: String,
  },
}
</script>

<template>
    <div class="accordion-item">
        <h2 class="accordion-header">
            <button class="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse"
                    :data-bs-target="'#collapse_' + index" aria-expanded="false" :aria-controls="'collapse_' + index">
                {{ question }}
            </button>
        </h2>
        <div :id="'collapse_' + index" class="accordion-collapse collapse" :class="{show: index === 0}"
             data-bs-parent="#accordionFaq">
            <div class="accordion-body" v-html="answer"></div>
        </div>
    </div>
</template>
