<script setup>
import HeaderProfile from '../components/AppHeaderProfile.vue'
</script>

<template>
    <HeaderProfile/>

    <div class="row">
        <div class="col-12" v-for="item in resources" :key="item.id">
            <div class="mb-5 py-0" v-if="item.id === taskId">
                <div class="d-flex align-items-center row mb-3">
                    <div class="col-lg-4">
                        <h3 class="fw-bold">Текущая проверка</h3>
                    </div>
                    <div class="col-lg-3 px-0">
                        <div class="d-flex align-items-center box_score rounded-pill text-white">Можно получить
                            <div class="d-flex align-items-center gap-1">
                                <label style="font-weight: bold; font-size: 1.5rem">{{item.points}}</label>
                                <svg width="30" height="30" viewBox="0 0 49 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.7065 1.63397C23.4402 0.147482 25.5598 0.14748 26.2935 1.63397L32.1167 13.4331C32.408 14.0234 32.9712 14.4326 33.6226 14.5272L46.6437 16.4193C48.2842 16.6577 48.9392 18.6736 47.7522 19.8307L38.33 29.015C37.8586 29.4745 37.6435 30.1365 37.7548 30.7853L39.979 43.7538C40.2593 45.3877 38.5444 46.6336 37.0771 45.8622L25.4307 39.7393C24.848 39.433 24.152 39.433 23.5693 39.7393L11.9229 45.8622C10.4556 46.6336 8.74073 45.3877 9.02095 43.7538L11.2452 30.7853C11.3565 30.1365 11.1414 29.4745 10.67 29.0151L1.24785 19.8307C0.0608118 18.6736 0.715831 16.6577 2.35627 16.4193L15.3774 14.5272C16.0288 14.4326 16.592 14.0234 16.8833 13.4331L22.7065 1.63397Z"
                                          fill="white"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Status -->
                <div class="mx-auto py-0">
                    <div class="bg-blue rounded-pill text-white col-lg-2 text-center fw-lighter">
                        {{ item.task_status.name }}
                    </div>
                </div>
                <!-- Address Name -->
                <div class="row mt-4">
                    <div class="fs-4 text-white text-start fw-bold">{{ item.checkpoint_address.name }}
                    </div>
                </div>
                <div class="row mt-2">
                    <!-- Date -->
                    <div class="col-lg-2">
                        <div class="fw-lighter mb-2">
                            Дата проверки
                        </div>
                        <div class="py-1 bg-blue rounded-pill text-center">
                            {{ item.date }}
                        </div>
                    </div>
                    <!-- Address -->
                    <div class="col-lg-4">
                        <div class="fw-lighter mb-2">
                            Адрес проверки
                        </div>
                        <div class="py-1 bg-blue rounded-pill text-center">
                            {{ item.checkpoint_address.address }}
                        </div>
                    </div>
                    <!-- Type -->
                    <div class="col-lg-2">
                        <div class="fw-lighter mb-2">
                            Тип проверки
                        </div>
                        <div class="py-1 bg-blue rounded-pill text-center">
                            {{ item.task_type.name }}
                        </div>
                    </div>
                </div>
                <!-- Instruction -->
                <div class="row mt-4">
                    <div class="col-12">
                        <button class="btn btn-primary collapsed" type="button" data-bs-toggle="collapse"
                                :data-bs-target="'#collapseInstruction_' + item.id" aria-expanded="false"
                                :aria-controls="'collapseInstruction_' + item.id">
                            Показать инструкцию
                        </button>
                    </div>
                </div>
                <!-- Instruction description -->
                <div class="row mt-2">
                    <div class="collapse" :id="'collapseInstruction_' + item.id">
                        <div class="card card-body text-black rounded-4" v-html="item.instruction.description"></div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <form class="mt-3 mt-lg-5 needs-validation" method="post" novalidate @submit="onSubmit">
                            <div class="col-lg-6">
                                <!-- Date time -->
                                <div class="mb-3">
                                    <label for="inputDateTime" class="form-label">Дата и время проверки</label>
                                    <input type="datetime-local" v-model="form.date_time"
                                           class="form-control rounded-pill"
                                           id="inputDateTime" placeholder="Дата и время проверки" required>
                                </div>
                                <!-- Amount -->
                                <div class="mb-3">
                                    <label for="inputAmount" class="form-label">Сумма заказа</label>
                                    <input type="number" v-model="form.amount"
                                           class="form-control rounded-pill" step="1" min="0"
                                           id="inputAmount" placeholder="Сумма заказа" required>
                                </div>
                                <!-- Comment -->
                                <div class="mb-3">
                                    <label for="inputComment" class="form-label">Комментарий</label>
                                    <textarea rows="5" v-model="form.comment"
                                              class="form-control rounded-4"
                                              id="inputComment" placeholder="Комментарий" required></textarea>
                                </div>
                                <!-- Submit -->
                                <button type="submit" :class="{ 'disabled' : loading}"
                                        class="btn btn-danger btn-lg px-5 rounded-pill my-4 text-white">
                                    <span class="spinner-grow spinner-grow-sm" aria-hidden="true" v-if="loading"></span>
                                    Отправить
                                    <span class="spinner-grow spinner-grow-sm" aria-hidden="true" v-if="loading"></span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import CustomerService from '@/services/customer.service'

export default {
  name: 'AppMyCheckReport',
  setup () {
    const route = useRoute()
    const checkId = computed(() => route.params.id)
    return {
      checkId,
    }
  },
  data () {
    return {
      taskId: parseInt(this.$route.params.id),
      resources: null,
      notFoundText: 'Проверяем... Ожидайте',
      loading: false,
      form: {
          date_time: '',
          amount: 0,
          comment: '',
          media: null,
          task_id: parseInt(this.$route.params.id),
          customer_id: ''
      },
    }
  },
  methods: {
    validateForm () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      const forms = document.querySelectorAll('.needs-validation')

      // Loop over them and prevent submission
      Array.from(forms).forEach(form => {
        form.addEventListener('submit', event => {
          if (!form.checkValidity()) {
            event.preventDefault()
            event.stopPropagation()
          }

          form.classList.add('was-validated')
        }, false)
      })
    },
    onSubmit (e) {
      e.preventDefault()
      const self = this
      self.loading = true
      CustomerService.saveReport(self.form).then(
        () => {
          self.$swal({
            icon: 'success',
              title: 'Ваш отчёт отправлен',
            text: 'Наш менеджер свяжется с вами после проверки отчёта!',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          })
          self.loading = false
            this.$router.push({ name: 'my' })
        },
        (error) => {
          console.error(error)
          self.$swal({
            icon: 'error',
            title: 'Что-то пошло не так...',
            text: 'Обновите страницу и попробуйте ещё раз.',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          })
        },
      )
    },
  },
  // mounted () {
  //   const self = this
  //   self.validateForm()
  //   CustomerService.getChecks().then(
  //     (response) => {
  //       if (!response.data.data.length) {
  //         self.notFoundText = 'У вас ещё нет заданий.'
  //         setTimeout(function () {
  //           self.$router.push('/my')
  //         }, 2000)
  //       } else {
  //         self.resources = response.data.data
  //       }
  //     },
  //     (error) => {
  //       self.resources =
  //         (error.response &&
  //           error.response.data &&
  //           error.response.data.message) ||
  //         error.message ||
  //         error.toString()
  //     },
  //   )

  //   self.form.customer_id = self.$store.state.auth.customer.id
  // },
}
</script>

<style scoped>

</style>