<script setup>
import Steps from "../components/AppSteps.vue";
import Faq from "../components/AppFaq.vue";
import Header from "../components/AppHeader.vue";
// import Footer from '../components/AppFooter.vue'
</script>
<template>
  <Header />
  <div class="row-container">
    <div class="main-col">
      <!-- Heading -->
      <h1 class="fw-bold mb-2">
        Стань тайным агентом
        <span class="text-uppercase">CHICKO</span>
        и&nbsp;получай сертификаты.&nbsp; 
      </h1>
      <h5 class="w-75">
        Сертификатом можно оплатить до 100% от заказа! Благодаря вам домик вашего города может стать еще лучше!
      </h5>
      <Steps
          @scrollToMainForm="scrollToMainForm"
      />
    </div>
    <div class="forms">
      <div class="main-col">
        <div class="d-grid gap-2 mb-2 buttons">
          <button
            type="button"
            class="btn btn-sm btn-outline-light rounded-pill mainForm"
            v-if="!viewLoginForm"
            @click="viewLoginForm = true"
          >
            войти
          </button>
          <button
            type="button"
            ref="mainFormLink"
            class="btn btn-sm btn-outline-light rounded-pill mainForm"
            v-else  
            @click="viewLoginForm = false"
          >
            Заполни Анкету, и подтвердить регистрацию через telegram
          </button>
        </div>
        <MainForm :scrollToMainForm="scrollToMainForm" v-if="!viewLoginForm" />
        <LoginForm v-else />
      </div>
    </div>
  </div>
  <section
    class="faq-page position-relative bg-white mx-lg-n4 px-lg-4 pt-2 pt-lg-5 pb-3 rounded-4 mb-3"
  >
    <Faq />
  </section>
  <!--    <Footer/>-->
</template>

<script>
import { defineAsyncComponent } from "vue";

const MainForm = defineAsyncComponent(() => import("@/components/AppMainForm.vue"));
const LoginForm = defineAsyncComponent(() => import("@/components/AppLoginForm.vue"));
export default {
  name: "HomePage",
  // eslint-disable-next-line vue/no-unused-components
  components: { MainForm, LoginForm },
  data() {
    return {
      viewLoginForm: false,
      aboba: "",
    };
  },
    methods: {
      scrollToMainForm() {
        window.scroll({
            left: 0,
            top: document.querySelector('.forms').offsetTop,
            behavior: "smooth",
        });
      }
    }
  // mounted() {
  //   const hash = window.location.hash.slice(1);
  //   const params = new URLSearchParams(hash);
  //   const initDataString = params.get("tgWebAppData");
  //   const initData = new URLSearchParams(initDataString);
  //   if (initData == null) {
  //     alert("Вы не вошли");
  //   } else {
  //     // alert("Вы вошли");
  //     // alert(initData);
  //     // this.aboba = JSON.parse(decodeURIComponent(initData.get("user")));
  //     // this.$router.push("/my");
  //     // alert(decodeURIComponent(initData));
  //     alert("Get", decodeURIComponent(initData));
  //     alert(decodeURIComponent(initData));
  //     let id = JSON.parse(decodeURIComponent(initData.get("user"))).id
  //     this.$store.dispatch("auth/login", id).then(
  //       data => {
  //         alert('String');
  //       },
  //       (error) => {
  //         this.errorAlert(error.response.data.message);
  //       }
  //     );
  //   }
  // },
};
</script>

<style lang="scss" scoped>
.row-container {
  display: flex;
  justify-content: space-between !important;
  flex-direction: row;
  width: 100%;
}

.main-col:last-child {
  width: 400px;
  float: right;
}

.btn {
  width: 550px;
}

.forms {
  width: 550px;
  float: right;
}

@media (max-width: 1199px) {
  .forms {
    position: absolute;
    top: 1100px;
    right: 100px;
  }
}
@media (max-width: 991px) {
  .forms {
    width: 450px;
  }
  .btn {
    width: 400px;
    position: absolute;
    right: -100px;
  }
}
@media (max-width: 767px) {
  .row-container {
    display: flex;
    flex-direction: column;
    height: 4500px;
    position: relative;
  }
  .forms {
    top: 3400px;
    display: flex;
    align-self: center;
  }
  .buttons {
    position: relative;
    left: 0;
  }
}
@media (max-width: 470px) {
  .buttons {
    left: -20px;
  }
  .btn {
    width: 300px;
  }
}
@media (max-width: 430px) {
  .buttons {
    left: 10px;
  }
}
@media (max-width: 430px) {
  .buttons {
    left: 30px;
  }
}
</style>
