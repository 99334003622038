<template>
  <div>
    <div
      class="d-flex align-items-center box_score rounded-pill text-white"
      @click="info"
    >
      Доступно баллов
      <div class="d-flex align-items-center gap-1">
        <label style="font-weight: bold; font-size: 1.5rem" id="points">{{
          points
        }}</label>
        <svg
          width="30"
          height="30"
          viewBox="0 0 49 47"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M22.7065 1.63397C23.4402 0.147482 25.5598 0.14748 26.2935 1.63397L32.1167 13.4331C32.408 14.0234 32.9712 14.4326 33.6226 14.5272L46.6437 16.4193C48.2842 16.6577 48.9392 18.6736 47.7522 19.8307L38.33 29.015C37.8586 29.4745 37.6435 30.1365 37.7548 30.7853L39.979 43.7538C40.2593 45.3877 38.5444 46.6336 37.0771 45.8622L25.4307 39.7393C24.848 39.433 24.152 39.433 23.5693 39.7393L11.9229 45.8622C10.4556 46.6336 8.74073 45.3877 9.02095 43.7538L11.2452 30.7853C11.3565 30.1365 11.1414 29.4745 10.67 29.0151L1.24785 19.8307C0.0608118 18.6736 0.715831 16.6577 2.35627 16.4193L15.3774 14.5272C16.0288 14.4326 16.592 14.0234 16.8833 13.4331L22.7065 1.63397Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerService from "@/services/customer.service";

export default {
  name: "BonusPoints",
  data() {
    return {
      points: 0,
      speed: 100,
      step: 1,
    };
  },
  methods: {
    info() {
      this.$swal({
        icon: "info",
        text: "Баллы начисляются автоматически после успешного проведения проверки!",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
      });
    },
    animatePoints(points) {
      let e = document.querySelector("#points");
      let n = 0;
      let t = Math.round(points / this.step / this.speed);
      let interval = setInterval(() => {
        n = n + this.step;
        if (n === points) {
          clearInterval(interval);
        }
        e.innerHTML = n;
      }, t);
    },
  },
  mounted() {
    const self = this;
    CustomerService.getPoints().then((response) => {
      if (parseInt(response.data) > 0) {
        // self.points = self.animatePoints(response.data);
        self.points = response.data;
      }
    });
  },
};
</script>

<style scoped></style>
