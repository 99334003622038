<template>
  <nav class="navbar navbar-expand-xl mb-lg-5">
    <div class="container-fluid px-0 fw-light">
      <div class="navbar-brand py-0 justify-content-start me-0">
        <img src="/img/logo.svg" alt="CHICKO" width="207" height="100" />
      </div>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link class="fw-normal nav-link" :to="{ name: 'my' }"
              >Профиль</router-link
            >
          </li>
          <!-- <li class="nav-item">
                        <router-link class="fw-normal nav-link" :to="{name: 'my-task'}" @click="replaceRoute('/my-task')">Задания</router-link>
                    </li> -->
          <li class="nav-item">
            <router-link class="fw-normal nav-link" :to="{ name: 'my-check' }"
              >Проверки</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="fw-normal nav-link" :to="{ name: 'my-achievement' }"
              >Награды</router-link
            >
          </li>
          <li class="nav-item">
            <router-link class="fw-normal nav-link" :to="{ name: 'faq' }"
              >F.A.Q.</router-link
            >
          </li>
          <li class="nav-item">
            <a href="#" class="fw-normal nav-link" @click.prevent="logOut">Выход</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push({ name: 'home' });
    },
  },
  mounted() {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  },
};
</script>
