<script setup>
import Header from '../components/AppHeader.vue'
</script>

<template>
  <Header/>
  <div class="row">
    <div class="col-12">
      <h1 class="text-center mb-3">Что такое инспекция</h1>
      <p>Инспекция — это проверка ресторана на соответствие стандартам. Чтобы провести инспекцию, необходимо посетить
        ресторан Chicko и сделать фотографии по чек-листу.</p>
    </div>
    <div class="col-12">
      <h2 class="text-center">Важно</h2>
      <ul>
        <li>Сотрудники имеют право не пустить инспектора с признаками заболевания на проверку.</li>
        <li>Инспекцию необходимо проводить в определенное время, указанное куратором.</li>
        <li>Если ты не можешь провести проверку, сообщи нам.</li>
      </ul>
    </div>
    <div class="col-12">
      <h2 class="text-center">Перед проверкой:</h2>
      <ul>
        <li>Подготовь фотоаппарат, планшет или смартфон</li>
        <li>Возьми документ: паспорт или права</li>
        <li>Изучи чек-лист</li>
        <li>Печатать чек-лист перед проверкой не нужно, необходимо взять с собой только телефон.</li>
      </ul>
    </div>
    <div class="col-12">
      <h2 class="text-center">Во время проверки</h2>
      <ul>
        <li>Когда придешь в ресторан, подойди к сотрудникам за кассовой стойкой.</li>
        <li>Представься и сообщи, что ты — инспектор Chicko.
          <br>Сотрудники выдадут шапочку, бахилы, халат, перчатки, маску
          и подскажут где можно переодеться и как пройти на кухню.
        </li>
        <li>Веди себя вежливо и корректно.</li>
        <li>Соблюдай гигиену: вымой и обработай руки специальным антисептиком в начале проверки; надень перчатки; не
          клади телефон и свои личные вещи на столы и поверхности на кухне; не бери посуду руками без перчаток.
        </li>
        <li>Делай качественные фотографии по чек-листу:
          все маркировки и буквенные обозначения должны быть в фокусе и хорошо различимыми; все фотографии должны быть
          четкие, светлые, без искажения цветопередачи и фильтров;
          если необходимого пункта чек-листа нет в ресторане, обязательно укажи это в анкете.
        </li>
      </ul>
    </div>
    <div class="col-12">
      <h2 class="text-center">После проверки</h2>
      <ul>
        <li>Отправь отчет<sup>*</sup> куратору в день проверки.
          Когда отчет будет проверен, тебе придет сообщение.
          <br><small class="text-info"><sup>*</sup>Фотографии должны быть в формате JPG или PNG и не должны превышать 20
            мб.</small>
        </li>
        <li>Если отчет отправлен на доработку
          доработай отчет в течение 24 часов после уведомления;
          добавь новые фотографии, дату и время доработки;
        </li>
        <li>Если отчет не доработан в течение 24 часов, проверка будет отменена и передана другому инспектору.</li>
      </ul>
    </div>
    <div class="col-12">
      <h2 class="text-center">Что делать, если сотрудники не пускают в ресторан</h2>
      <ul>
        <li>Сотрудники ресторана могут не пустить тебя на проверку, если ты пришел не в то время, которое указано в
          чек-листе. В этом случае необходимо повторить проверку в нужное время.
        </li>
        <li>Если ты пришел в пиццерию в правильное время, и тебя не пустили, сообщи нам.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckHall',
}
</script>

<style scoped>

</style>