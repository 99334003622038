import { createRouter, createWebHistory } from 'vue-router'

import HomePage from '@/pages/HomePage.vue'
import CheckHall from '@/pages/CheckHall.vue'
import RulesPage from '@/pages/RulesPage.vue'
import MyPage from '@/pages/MyPage.vue'
import CheckRequestPage from '@/pages/CheckRequestPage.vue'
// import MyCheckPage from '@/pages/MyCheckPage.vue'
import CheckReportPage from '@/pages/CheckReportPage.vue'
import AgreementPage from '@/pages/AgreementPage.vue'
const FaqPage = () => import("@/pages/FaqPage.vue")
import MyAchievementPage from '@/pages/MyAchievementPage.vue'
import AppMyCheckReport from '@/components/AppMyCheckReport.vue'
const MyTaskPage = () => import('@/pages/MyTaskPage.vue')
import FormPage from '@/pages/FormPage.vue'
import ReportFill from '@/pages/ReportFill.vue'
import RequestTwaChecks from '@/pages/RedirectTwaChecks.vue'
import RequestTwaMy from '@/pages/RedirectTwaMy.vue'
import AuthService from '../services/auth.service'

let loggedIn = () => {
  let token = localStorage.getItem('token');

  return Boolean(token);
}
// loggedIn = Boolean(loggedIn);
// console.log(loggedIn);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqPage
  },
  {
    path: '/check-hall',
    name: 'check-hall',
    component: CheckHall,
  },
  {
    path: '/rules',
    name: 'rules',
    component: RulesPage,
  },
  {
    path: '/form',
    name: 'form',
    component: FormPage,
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: AgreementPage
  },
  {
    path: '/my',
    name: 'my',
    component: MyPage,
    meta: { requiresAuth: true},
    // beforeEnter: (to, from, next) => {
    //   console.log(loggedIn(), 'ads')
    //   if (!loggedIn()) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/my-achievement',
    name: 'my-achievement',
    component: MyAchievementPage,
    meta: { requiresAuth: true},
      // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/check-request',
    name: 'check-request',
    component: CheckRequestPage,
    meta: { requiresAuth: true},
      // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  // {
  //   path: '/my-check',
  //   name: 'my-check',
  //   component: MyCheckPage
  // },
  {
    path: '/my-check',
    name: 'my-check',
    component: MyTaskPage,
    meta: { requiresAuth: true},
    // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: "/my-check/:id",
    name: 'MyCheckReport',
    component: AppMyCheckReport,
    meta: { requiresAuth: true},
    // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/check-report',
    name: 'check-report',
    component: CheckReportPage,
    meta: { requiresAuth: true},
    // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/CheckRequestPage/:propValue',
    name: 'CheckRequestPage',
    component: CheckRequestPage,
    props: true,
    meta: { requiresAuth: true},
    // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/report/:id/edit',
    name:'report-edit',
    component: ReportFill,
    meta: { requiresAuth: true},
    // beforeEnter: (to, from, next) => {
    //   if (!loggedIn) {
    //     next('/');
    //   } else {
    //     next();
    //   }
    // },
  },
  {
    path: '/redirect-twa-checks',
    name: 'redirect-twa-checks',
    component: RequestTwaChecks,
  },
  {
    path: '/redirect-twa-my',
    name: 'redirect-twa-my',
    component: RequestTwaMy,
  },
]

// export default function () {
  const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
})
// }

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !AuthService.isAuthentificated()) {
        next('/login')
    } else {
        next()
    }
})

export default router;


