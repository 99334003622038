<template>
  <section class="steps">
    <div class="row">
      <div class="col-12 mb-4">
        <h2 class="fw-bold">Что нужно сделать?</h2>
      </div>
    </div>
    <div class="row my-5">
      <div class="row-column">
        <!-- Step 1 -->
        <div class="col-sm-6 align-self-start step1">
          <div class="step step_01 position-relative">
            <div class="step-title d-flex">
              <h3 class="d-inline fw-bold me-2 position-relative">1</h3>
              <span class="step1__text">Заполни <a @click.prevent="scrollToMainForm" id="scrollToMain" class="text-white">Анкету</a>, и подтвердить регистрацию через Telegram</span>
            </div>
            <img
              src="/img/step_01.png"
              alt="step_01"
              class="img-fluid position-relative"
            />
          </div>
        </div>

        <img src="@/icon/to2.svg" alt="" class="line-to-block to2" />

        <!-- Step 2 -->
        <div class="col-sm-6 align-self-end item2">
          <div class="step step_02 position-relative">
            <div class="step-title d-flex w-75 ms-lg-5">
              <h3 class="d-inline fw-bold me-2 position-relative">2</h3>
              <span>Принять приглашение на&nbsp;проверку домика&nbsp;</span>
            </div>
            <img
              src="/img/step_02.png"
              alt="step_02"
              class="img-fluid position-relative"
            />
          </div>
        </div>
      </div>

      <img src="@/icon/to3.svg" alt="" class="line-to-block to3" />

      <!-- Step 3 -->
      <div class="col-sm-6 align-self-start item3">
        <div class="step step_03 position-relative">
          <div class="step-title d-flex">
            <h3 class="d-inline fw-bold me-2 position-relative">3</h3>
            <span class="step3__text">В назначенный день <br />проведи проверку <br />по чек-листу</span>
          </div>
          <img src="/img/step_03.png" alt="step_03" class="img-fluid position-relative" />
        </div>
      </div>

      <img src="@/icon/to4.svg" alt="" class="line-to-block to4" />

      <!-- Step 4 -->
      <div class="col-sm-6 align-self-end item4">
        <div class="step step_04 position-relative">
          <div class="step-title d-flex">
            <h3 class="d-inline fw-bold me-2 position-relative">4</h3>
            <span style="margin-top: -20px;">Составь отчет <br />по инструкции</span>
          </div>
          <img src="/img/step_04.png" alt="step_04" class="img-fluid position-relative" />
        </div>
      </div>

      <img src="@/icon/to5.svg" alt="" class="line-to-block to5" />

      <!-- Step 5 -->
      <div class="col-sm-6 align-self-start item5">
        <div class="step step_05 position-relative">
          <div class="step-title d-flex">
            <h3 class="d-inline fw-bold me-2 position-relative">5</h3>
            <span>Получи сертификат и баллы за проверку</span>
          </div>
          <img src="/img/step_05.png" alt="step_05" class="img-fluid position-relative" />
        </div>
      </div>

      <img src="@/icon/to6.svg" alt="" class="line-to-block to6" />

      <!-- Step 6 -->
      <div class="col-sm-6 align-self-end item6">
        <div class="step step_06 position-relative">
          <div class="step-title d-flex">
            <h3 class="d-inline fw-bold me-2 position-relative">6</h3>
            <span style="margin-left: 10px;">Сертификатом можно <br />полностью оплатить <br />заказы</span>
          </div>
          <img src="/img/step_06.png" alt="step_06" class="img-fluid position-relative" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    methods: {
        scrollToMainForm() {
            this.$emit('scrollToMainForm');
            console.log(1111);
        }
    }
};
</script>

<style lang="scss" scoped>
.my-5 {
  height: 2100px;
  position: relative;
}

.row-column {
  display: flex;
  justify-content: space-between;
  flex-direction: row !important;
}

.line-to-block {
  position: absolute;
  left: 140px;
  top: 60px;
}

.to3 {
  position: absolute;
  left: 40px;
  top: 220px;
  width: 450px;
  height: 430px;
}

.step1__text {
  margin-left: 40px;
}

.to4 {
  width: 340px;
  height: 160px;
  position: absolute;
  top: 680px;
}

.to5 {
  width: 430px;
  height: 390px;
  position: absolute;
  top: 855px;
  left: 70px;
}

.to6 {
  position: absolute;
  top: 1240px;
  left: 120px;
  width: 400px;
  height: 150px;
}

.img-fluid {
  width: 400px;
  height: 400px;
}

.item2 {
  position: absolute;
  right: 100px !important;
  top: 0 !important;
}
.item3 {
  position: absolute;
  left: 50px;
  top: 640px !important;
}
.item4 {
  position: absolute;
  right: 30px !important;
  top: 690px !important;
  .img-fluid {
    transform: rotate(340deg);
    z-index: -1;
  }
  span {
    z-index: 1;
  }
}

.item5 {
  position: absolute;
  left: 80px;
  top: 1280px !important;
}
.item6 {
  position: absolute;
  left: 500px;
  top: 1290px !important;
}

@media (max-width: 1399px) {
  .step1__text {
    margin-top: -30px !important;
    width: 622px;
  }
  .img-fluid {
    width: 350px;
    height: 350px;
  }
  .item2 {
    right: 0px !important;
    top: 10px !important;
  }
  .item4 {
    right: -50px !important;
    top: 700px !important;
  }
}

@media (max-width: 1199px) {
  .step3__text {
    margin-left: 10px;
  }
  .my-5 {
    display: flex;
    flex-direction: column;
    height: 2400px;
  }
  .row-column {
    display: flex;
    flex-direction: row !important;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .col-sm-6 {
    position: absolute;
    left: 0;
  }
  .item2 {
    top: -120px !important;
    left: 60% !important;
  }
  .to2 {
    content: url("@/icon/to2Tab.svg");
    top: 20px;
    left: 125px;
  }
  .to3 {
    content: url("@/icon/to3Tab.svg");
    top: 80px;
    left: 200px;
  }
  .item3 {
    top: 550px !important;
    left: 100px;
  }
  .to4 {
    content: url("@/icon/to4Tab.svg");
    top: 570px;
    left: 15px;
    width: 150px;
    height: 530px;
  }
  .item4 {
    top: 980px !important;
    left: 80px;
  }
  .to5 {
    content: url("@/icon/to5Tab.svg");
    top: 1150px;
    left: -130px;
  }
  .item5 {
    top: 1580px !important;
    left: 100px;
  }
  .item6 {
    top: 2000px !important;
  }
  .to6 {
    content: url("@/icon/to6Tab.svg");
    top: 1580px;
    left: -100px;
    height: 427px;
  }
  .item6 {
    top: 1930px !important;
    left: 110px;
  }
}

@media (max-width: 991px) {
  .step1 {
    width: 600px;
  }
  .step1__text {
    margin-top: -30px !important;
    width: 400px;
  }
  .item2 {
    left: 630px !important;
    top: -120px !important;
  }
}

@media (max-width: 767px) {
  .my-5 {
    flex-direction: column !important;
    height: 2700px;
  }
  .big-height {
    height: 2200px !important;
  }
  .row-column {
    flex-direction: column;
  }
  .img-fluid {
    width: 343px !important;
    height: 343px !important;
  }
  .col-sm-6 {
    width: 354px;
    height: 480px;
  }
  .to2 {
    content: url("@/icon/to2Smt.svg");
    top: 100px;
    left: -30px;
  }
  .item2 {
    left: 80px !important;
    top: 320px !important;
  }
  .to3 {
    content: url("@/icon/to3Smt.svg");
    top: 520px;
    left: -190px;
  }
  .item3 {
    left: 30px !important;
    top: 960px !important;
  }
  .to4 {
    content: url("@/icon/to4Smt.svg");
    top: 970px;
    left: -60px;
  }
  .item4 {
    left: 30px !important;
    top: 1370px !important;
  }
  .to5 {
    content: url("@/icon/to5Smt.svg");
    top: 1520px;
    left: -190px;
  }
  .item5 {
    left: 30px !important;
    top: 1950px !important;
  }
  .to6 {
    content: url("@/icon/to6Smt.svg");
    top: 1930px;
    left: -190px;
  }
  .item6 {
    left: 30px !important;
    top: 2260px !important;
  }
  .steps {
    margin-left: 20px;
  }
}
@media (max-width: 430px) {
  .to3,
  .to5 {
    left: -180px;
  }
  .to6 {
    left: -190px;
  }
}
@media (max-width: 400px) {
  .to3,
  .to5 {
    left: -165px;
  }
  .to6 {
    left: -180px;
  }
}
</style>
