<script setup>
import Header from '../components/AppHeader.vue'
</script>
<template>
  <Header/>
    <div class="row">
        <div class="col-lg-12">
            <h1>Ответ о проверке</h1>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CheckReportPage',
}
</script>

<style scoped>

</style>