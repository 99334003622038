<script setup>
import FaqItem from './AppFaqItem.vue'
</script>

<script>
import axios from 'axios'

export default {
  data () {
    return {
      resources: [],
    }
  },
  methods () {

  },
  mounted () {
    const self = this
    axios.get('/getfaqs').then(function (response) {
      let result = []
      response.data.data.forEach((faq) => {
        if (faq.status) {
          result.push(faq)
        }
      })
      self.resources = result.reverse()
    })
  },
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <h2 class="text-center text-black" id="faqs">Вопрос - ответ</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="accordion accordion-flush" id="accordionFaq">
                <!-- eslint-disable-next-line -->
                <FaqItem
                        v-for="(item, key) in resources"
                        :index="key"
                        :question="item.question"
                        :answer="item.answer"
                ></FaqItem>
            </div>
        </div>
    </div>
</template>
