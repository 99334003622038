<template>
  <div class="wrapper">
    <section>
      <AppHeader />
      <div class="main-title">
        {{ dateHumanization(instruction.task.check_date) }}, {{ instruction.task.name }}
        <!-- <p class="main-title__state">принято</p> -->
      </div>
      <div class="details-container">
        <div class="details">
          <p class="title">Дата проверки</p>
          <span
            ><p class="more-info">
              {{ dateHumanization(instruction.task.check_date) }}
            </p></span
          >
        </div>
        <div class="details">
          <p class="title">Адрес проверки</p>
          <div class="hover-cont tooltip-container">
            <p class="trigger">
              {{ instruction.task.restaurant.address }}
            </p>
            <!-- <p class="hover-text tooltip" :style="opacityStyle" v-if="tooltipVisible">
              {{ instruction.task.restaurant.address }}
            </p> -->
          </div>
        </div>
        <!-- <div class="details">
          <p class="title">Тип проверки</p>
          <span
            ><p class="more-info">{{ instruction.name }}</p></span
          >
        </div> -->
      </div>

      <div class="actions">
        <h2 class="subtitle">Что нужно сделать</h2>
        <h1 class="title">{{ instruction.task.description }}</h1>
      </div>

      <div class="parent-line">
        <hr class="line" />
      </div>

      <div class="main-info">
        <div class="safe-tecknic">
          <h1 class="safe__title">До проверки изучи информацию:</h1>
          <h2 class="safe__subtitlr">Время проверки - не более 60 минут</h2>
          <ul class="safe__list">
            <li class="list__item">
              Делай фотографии по чек-листу и внимательно читай описание с требованиями к
              фото.
            </li>
            <li class="list__item">
              Все фотографии из блока «Зал» обязательны для фотографирования. В случае
              отсутствия одной из фотографий, мы будем вынуждены отправить тебя в ресторан
              на доработку.
            </li>
<!--            <li class="list__item">-->
<!--              Если что-то, на твой взгляд, в домике CHICKO нужно исправить — тоже делай фото и-->
<!--              прикладывай его к отчету с комментарием.-->
<!--            </li>-->
          </ul>
        </div>
        <div class="check" v-for="(data, index) in varForArr" :key="index">
          <h2 class="check-title">{{ data.name }}</h2>
          <hr class="line" />
          <div
            class="checking-container"
            v-for="(field, i) in data.fields"
            :key="field.id"
          >
            <button class="btn" @click="toggleIcon">
              <span>{{ field.name }}</span>
            </button>
            <div class="checking__info">
              <!-- <p class="checking__text">
                Тебе должны выдать форму: одноразовые халат, бахилы, шапочку и
                перчатки.Если что-то из этого тебе не выдали или выдали в ненадлежащем
                виде (порваны / испачканы), сфотографируй для отчета.
              </p> -->
              <div class="row-files" v-if="field.requirePhoto">
                <div
                  class="uploaded-container"
                  v-for="(image, index) in uploadedImages[field.id]"
                  :key="index"
                >
                  <img
                    class="uploaded-img"
                    :src="image"
                    alt="Загруженное изображение"
                    v-if="image"
                    width="150"
                  />
                  <button class="delete-btn" @click="deleteImage(field.id, index)">
                    <img src="@/icon/delete.svg" alt="" class="delete-icon" />
                  </button>
                </div>
                <button
                  v-if="uploadedImages[field.id] == undefined ? true : uploadedImages[field.id].length < 5"
                  class="add-file-btn"
                  @click="openFileInput(field.id)"
                >
                  +
                </button>
                <input
                  name="file[]"
                  :id="`fileInput-${field.id}`"
                  type="file"
                  @change="handleImageUpload(field.id)"
                  style="display: none"
                  multiple
                />
              </div>
              <div class="row-buttons">
                <button
                  class="btn agreement btn_decline"
                  :class="field.answer === 1 ? 'btn_accept' : ''"
                  @click="ChangeAnswer(1, index, i)"
                >
                  Да
                </button>
                <button
                  class="btn agreement btn_decline"
                  :class="
                    field.answer === 0 && field.answer !== null ? 'btn_accept' : ''
                  "
                  @click="ChangeAnswer(0, index, i)"
                >
                  Нет
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="checking-container">
          <button class="btn" @click="toggleIcon">
            <span>Поделись своими наблюдениями</span>
          </button>
          <div class="checking__info br">
            <textarea type="text" class="checking__input" align=""></textarea>
          </div>
        </div>
        <button @click="sendData" class="send">Отправить</button>
      </div>

      <!-- <AppFooter /> -->
    </section>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeaderProfile.vue";
import CustomerService from "../services/customer.service";
import moment from "moment";

// import AppFooter from '@/components/AppFooter.vue'
import axios from "axios";
import { ref } from "vue";
export default {
  name: "ReportFill",
  components: {
    AppHeader,
    // AppFooter,
  },

  data() {
    return {
      instruction: {},

      currentFieldId: null,

      uploadedImages: {},
      result: ref([]),

      isChecked: false,

      clickedTime: null,

      varForArr: [],

      answer: null,

      // tooltipVisible: false,
      // opacityStyle: "opacity: 0;",
    };
  },

  mounted() {
    // this.getInstruction()
    // if (!this.tooltipVisible) {
    //   this.opacityStyle = "opacity: 1;";
    // } else {
    //   this.opacityStyle = "opacity: 0;";
    // }
  },
  methods: {
    getInstruction() {
      axios.get("http://127.0.0.1:8000/api/instruction/1").then((response) => {
        this.instruction = response.data;
      });
    },
    openFileInput(id) {
      const fileInput = document.getElementById(`fileInput-${id}`);
      if (fileInput) {
        fileInput.click();
      }
    },
    handleImageUpload(id) {
      const fileInput = document.getElementById(`fileInput-${id}`);
      const files = fileInput.files;
      if (files.length > 0) {
        const reader = new FileReader();
        this.result = this.uploadedImages[id] || ref([]); // Используем существующий массив или создаем новый
        // Проверяем, чтобы не превысить максимальное количество (5) изображений
        if (this.result.length + files.length > 5) {
          return;
        }
        reader.onload = () => {
          for (let i = 0; i < files.length; i++) {
            if (this.result.length + i < 5) {
              // Проверка на максимальное количество
              this.result.push(reader.result);
              this.varForArr[id.replace(/.\d$/, "") - 1].fields[
                id.replace(/^\d./, "") - 1
              ].images = this.result;
            }
          }
          // Сохраняем массив изображений в uploadedImages[id]
          this.uploadedImages[id] = this.result;

          console.log(this.uploadedImages)
          // Выводим актуальное состояние массива
        };

        reader.readAsDataURL(files[0]);
      }
    },
    deleteImage(id, index) {
      if (this.uploadedImages[id]) {
        this.varForArr[id.replace(/.\d$/, '') - 1].fields[id.replace(/^\d./, '') - 1].images.splice(index, 1) // Удаление изображения по индексу
      }
    },
    toggleIcon() {
      this.isChecked = !this.isChecked;
    },
    showTime() {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      this.clickedTime = `${hours}:${minutes}`;
    },
    checkInitAnswer(groupIndex, fieldIndex) {
      if (this.varForArr[groupIndex].fields[fieldIndex].answer === null) {
        this.varForArr[groupIndex].fields[fieldIndex].answer = 0;
      }
    },
    ChangeAnswer(answer, groupIndex, fieldIndex) {
      this.varForArr[groupIndex].fields[fieldIndex].answer = answer;
        console.log(this.varForArr);
    },
    dateHumanization(date) {
      const ruLocale = {
        months: [
          "Января",
          "февраля",
          "марта",
          "апреля",
          "мая",
          "июня",
          "июля",
          "августа",
          "сентября",
          "октября",
          "ноября",
          "декабря",
        ],
        weekdays: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
      };

      moment.updateLocale("ru", ruLocale);
      return moment(date).format("Do MMMM, dddd");
    },
    sendData() {
      for (let i = 0; this.varForArr.length > i; i++) {
        for (let j = 0; this.varForArr[i].fields.length > j; j++) {
          if(this.varForArr[i].fields[j].requirePhoto) {
            if (
                this.varForArr[i].fields[j].answer === undefined ||
                this.varForArr[i].fields[j].answer !== 0 &&
                this.varForArr[i].fields[j].images == undefined
              ) {
                return function() {
                  this.$swal({
                    title: "Ошибка",
                    text: 'Пожалуйста введите все значения ДА/НЕТ и выберите как минимум одну картинку для пункта в котором есть такая возможность!',
                    icon: "error",
                    showCancelButton: true,
                    showConfirmButton: false,
                    confirmButtonColor: "#00B2BB",
                    cancelButtonColor: "#FF0975",
                    cancelButtonText: "Закрыть",
                  })
              }.bind(this)();
            } else if(this.varForArr[i].fields[j].images !== undefined && this.varForArr[i].fields[j].images[0] === undefined && this.varForArr[i].fields[j].answer !== 0) {
                return function() {
                  this.$swal({
                    title: "Ошибка",
                    text: 'ПожалуйстаАААААААА введите все значения ДА/НЕТ и выберите как минимум одну картинку для пункта в котором есть такая возможность!',
                    icon: "error",
                    showCancelButton: true,
                    showConfirmButton: false,
                    confirmButtonColor: "#00B2BB",
                    cancelButtonColor: "#FF0975",
                    cancelButtonText: "Закрыть",
                  })
                }.bind(this)();
            }
          }
        }
      }
      this.$swal({
        title: "Вы уверены что хотите отправить отчёт?",
        text: 'Если нажмёте "Продолжить", то больше не сможете его изменить.',
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#00B2BB",
        cancelButtonColor: "#FF0975",
        confirmButtonText: "Подтвердить!",
        cancelButtonText: "Отмена",
      }).then((result) => {
        console.log(this)
        if(result.isConfirmed) {
          console.log(this.varForArr)
          // console.log(JSON.parse(this.varForArr))
          axios
            .post(`/customers/send-report/${this.$route.params.id}`, this.varForArr)
            .then((response) => {
              console.log(response);
                setTimeout(() => this.$router.push(({ name: 'my-check' })), 2000);

            })
            .catch((error) => {
              console.log(error);
                setTimeout(() => this.$router.push(({ name: 'my-check' })), 2000);
            });
          this.loading = true;

        }
      });
    }
    // confirmCheck(item_id) {
    //   this.loading = true;
    //   this.$swal({
    //     title: "Вы готовы начать проверку?",
    //     text: "Продолжив, вы подтверждаете что ознакомились с инструкцией",
    //     icon: "info",
    //     showCancelButton: true,
    //     confirmButtonColor: "#00B2BB",
    //     cancelButtonColor: "#FF0975",
    //     confirmButtonText: "Подтвердить!",
    //     cancelButtonText: "Отмена",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       CustomerService.confirmCheck(item_id).then(
    //         () => {
    //           this.$swal({
    //             icon: "success",
    //             title: "Отлично!",
    //             text:
    //               "Вы успешно подтвердили начало проверки. Желаем удачи и ждем от вас подробный отчёт.",
    //             showConfirmButton: false,
    //             timer: 2000,
    //             timerProgressBar: true,
    //           });
    //           setTimeout(this.$router.push("/my-check/" + item_id), 2000);
    //         },
    //         (error) => {
    //           this.resources = null;
    //           console.error(error);
    //           this.loading = false;
    //           this.$swal({
    //             icon: "error",
    //             title: "Упс...",
    //             text: "Что-то пошло не так. Попробуйте позже.",
    //             showConfirmButton: false,
    //             timer: 2000,
    //             timerProgressBar: true,
    //           });
    //         }
    //       );
    //     }
    //   });
    // },
  },

  created() {
    // axios.get("/getinstructions").then((response) => {
    //   this.instruction = response.data;
    //   const dataForReport = JSON.parse(response.data.form_data);
    //   this.varForArr = dataForReport;
    // });
    // axios.get(`/customers/report/${this.$route.params.id}`).then((response) => {
    //   console.log(response);
    //   console.log(this.$route.params.id);
    // });
    CustomerService.getReport(this.$route.params.id).then((response) => {
      console.log(response.data);
      this.instruction = response.data;
      const dataForReport = JSON.parse(response.data.report_data);
      console.log(dataForReport)
      this.varForArr = dataForReport;
    });
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  min-width: 90%;
  overflow-x: hidden !important;
}
.main-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 66px;
  font-size: 40px;
  font-family: Rubik;
  font-weight: 700;
  text-transform: uppercase;
  p.main-title__state {
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    font-family: Overpass;
    padding: 11px 17px;
    background: #00b2bb;
    border-radius: 100px;
    margin-left: 40px;
  }
}

.details-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.trigger {
  background-color: #00b2bb;
  border-radius: 100px;
  font-family: Overpass;
  font-size: 20px;
  font-weight: 400;
  z-index: 10;
  width: 313px;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  padding-left: 10px;
  &:hover {
    white-space: normal;
  }
}

// .tooltip-container {
//   position: relative;
//   display: inline-block;
// }

// .tooltip {
//   color: #fff;
//   padding: 5px;
//   border-radius: 100px;
//   transition: all 0.3s;
//   background-color: #ff0975 !important;
//   top: 60px;
//   width: 100% !important;
//   white-space: normal !important;
//   padding: 15px;
// }
.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
  margin-right: 50px;
  width: auto;
  align-self: center;
  .title {
    font-size: 20px;
    font-family: Overpass;
    font-weight: 400;
  }
  // span {

  //     display: flex;
  //     justify-content: center;
  //     align-items: flex-end;
  //     height: 47px;
  //     position: relative;
  //     z-index: 10;
  // }
  .more-info {
    background-color: #00b2bb;
    border-radius: 100px;
    font-family: Overpass;
    font-size: 20px;
    font-weight: 400;
    z-index: 10;
    padding: 10px !important;
  }
}
.actions {
  margin-top: 50px;
  .subtitle {
    font-size: 20px;
    font-family: Overpass;
    font-weight: 400;
  }
  .title {
    font-size: 24px;
    font-family: Overpass;
    font-weight: 700;
    margin-top: 20px;
  }
}

.checking-container {
  margin-top: 0px;
}

.parent-line {
  position: relative;
  margin-bottom: 35px;
}

.line {
  bottom: none;
  height: 2px;
  width: 80vw;
  position: absolute;
  // left: -100px !important;
}

.safe__title {
  font-size: 24px;
  font-family: Overpass;
  font-weight: 700;
  margin-top: 35px;
}

.safe__subtitlr {
  margin-top: 24px;
  font-size: 20px;
  font-family: Overpass;
  font-weight: 700;
}

.safe__list {
  .list__item {
    margin-top: 23px;
    max-width: 495px;
  }
}

.check-title {
  margin-top: 40px;
}

.check .line {
  margin-bottom: -100px !important;
  width: 80vw;
}

.btn-container {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: flex-end;
  .time {
    color: #231f20;
    font-family: Overpass;
    font-size: 20px;
    font-weight: 400;
    width: 99px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #fff;
    margin-bottom: 0;
    margin-left: 18px;
  }
}

.btn {
  font-size: 20px;
  font-family: Overpass;
  font-weight: 400;
  margin-top: 80px;
  padding: 10px 20px;
  border-radius: 100px;
  background: #00b2bb;
  color: #fff;
  position: relative;
  // span {
  //     margin-right: 100px;
  // }
  &:hover {
    background: #00b2bb;
    color: #fff;
  }
  // .icon {
  //     fill: #fff;
  //     transition: transform 0.3s;
  // }
  // .checked .icon {
  //     transform: rotate(45deg);
  // }
  &_accept {
    background: #ff0975 !important;
    color: #fff !important;
    margin-right: 20px !important;
    //&:hover {
    //  background: rgba(255, 9, 117, 0.6);
    //  transition: all 0.3s;
    //}
  }
  &_decline {
    background: #00b2bb;
    color: #fff;
    margin-right: 20px;
    //&:hover {
    //  background: rgba($color: #00b2bb, $alpha: 0.6);
    //  transition: all 0.3s;
    //}
  }
  &-selected {
    box-shadow: inset 2px 2px 2px #4f4f4f;
  }
}

.checking__info {
  max-width: 466px;
  margin-top: 20px;
  border-left: 3px solid #00b2bb;
  padding-left: 10px;
}

.checking__text {
  font-size: 20px;
  font-family: Overpass;
  font-weight: 400;
  margin-top: 20px;
}

.row-files {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 910px;
}

.add-file-btn {
  width: 168px;
  height: 168px;
  background: #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  border-radius: 10px;
  color: #5e5e5e;
  border: none;
}

.uploaded-container {
  position: relative;
  &:hover {
    .delete-btn {
      opacity: 1;
      transition: all 0.3s;
    }
  }
}

.delete-btn {
  opacity: 0;
  border: none;
  background: #ff0975;
  border-radius: 50%;
  padding-bottom: 5px;
  position: absolute;
  top: 10px;
  right: 20px;
  transition: all 0.3s;
  &:hover {
    background: rgba(255, 9, 117, 0.6);
    transition: all 0.3s;
  }
}

.uploaded-img {
  width: 168px !important;
  height: 168px !important;
  object-fit: cover;
  border-radius: 10px;
  margin-right: 13px;
  margin-bottom: 13px;
}

.checking__input {
  width: 460px;
  height: 107px;
  border-radius: 10px;
  vertical-align: top;
}

.send {
  padding: 20px 40px;
  background: #ff0975;
  border-radius: 100px;
  margin-top: 100px;
  border: none;
  color: #fff;
  font-family: Overpass;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 80px;
}

@media (max-width: 990px) {
  .details {
    .title {
      font-size: 12px;
    }
    .more-info {
      font-size: 12px;
    }
  }
  .row-files {
    width: 550px;
  }
}
@media (max-width: 767px) {
  .main-title {
    display: flex;
    flex-direction: column;
    align-self: center;
    text-align: center;
    margin-top: 100px;
    position: relative;
    .main-title__state {
      text-align: center;
    }
  }

  .details-container {
    flex-direction: column;
    width: 100%;
    position: relative;
    &::after,
    &::before {
      content: "";
      flex: 1;
      background-color: #f2f2f2;
    }
  }
  .details {
    padding: 17px !important;
    width: auto !important;
    text-align: center;
    .more-info,
    .title {
      font-size: 20px;
    }
    .more-info {
      background-color: #00b2bb;
      flex: 1;
      padding: 2px;
    }
  }

  .actions {
    text-align: center;
  }
  .line {
    // left: -120px;
    width: 100%;
  }

  .delete-btn {
    opacity: 1;
  }
}
@media (max-width: 620px) {
  .row-files {
    width: 200px;
  }
  .uploaded-img {
    width: 306px !important;
    height: 158px !important;
  }
  .checking__input {
    width: 300px;
  }
}
</style>
